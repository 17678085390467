@use '../../App.scss' as variables;

.header__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    flex-direction: column;
}

.header__image-container {
    width: 30%;
    height: auto;
    max-width: 250px;

    img:first-child {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }

    img:nth-child(2) {
        cursor: pointer;
    }

    @media screen and (min-width: 1100px) {
        width: 20%;
    }
}

.header__image-reduced-container {
    width: 58px;
    height: 58px;
}

.header__animated-text {
    transition: transform 0.2s ease-in-out;
    margin-block-start: 0;

    &:hover {
        transform: scale(0.7); 
    }
}

.header__linkedin-image-container {
    position: relative;
    width: 30px;
    height: 30px;
    bottom: 60px;
    left: 80%;

    @media screen and (max-width: variables.$medium) {
        width: 20px;
        height: 20px;
        left: 80%;
        bottom: 30px;
    }
}

.header__reduced-container {
    cursor: pointer;
    margin-right: 8px;
}
