.form__container {
    display: flex;
    gap: 10px;
    width: 100%;
}

.form__icon {
    color: #1c2732;
    &.disabled{
        color: rgba(28, 39, 50, 0.6);
    }
}