.message__container {
    display: flex;
    width: 100%;
    border-radius: 8px;
    background-color: rgba(31, 30, 31, 255);
    color: white;
    font-size: 0.8em;
}

.message__not-found-container {
    font-style: italic;
    font-size: 0.8em;
    margin-block-start: 0;

    p:first-child {
        margin-block-start: 0;
    }

}


.message_break-line-container{
    white-space: pre-line;
    padding: 1em 8px;
}