.input__container {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0.5em;
    background-color: white;
    width: 100%;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.input_custom-input {
    flex-grow: 1;
    border: none;
    outline: none;
}

.input__icon-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    color: #1c2732;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
    }
    &-disabled{
        color: rgba(28, 39, 50, 0.6);
        &:hover {
            cursor: default;
            background: none;
        }
    }
}

.input__icon-container{
    color: #1c2732; 
}