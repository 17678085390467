$backgroundColor: #efefef;
$small: 480px;
$medium: 768px;


body{
  background-color: $backgroundColor;
  font-family: "Roboto", sans-serif;
  color: rgba($color: #1c2732, $alpha: 1.0);
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app__container{
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
}

.app__loading-container{
  color: #1c2732 !important;
  align-self: center;
}

.app__content{
  display: flex;
  flex-direction: column;
  width: 50vw;
  gap: 30px;
  justify-content: center;
  min-height: 70vh;
  @media screen and (max-width: $small) {
    width: 90vw;
    gap: 20px;
  }
  @media screen and (min-width: $medium) {
    width: 60vw;
  }
}

.app__reduced-content{
  display: flex;
  flex-direction: row;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}
